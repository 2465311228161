<script>
export default {
  name: 'RLoader'
}
</script>

<template>
  <div class="w-full p-10 flex flex-row space-x-4 justify-center">
    <div
      class="h-4 w-4 rounded-full bg-primary transition-opacity duration-300"
    ></div>
    <div
      class="h-4 w-4 rounded-full bg-primary transition-opacity duration-300"
    ></div>
    <div
      class="h-4 w-4 rounded-full bg-primary transition-opacity duration-300"
    ></div>
  </div>
</template>

<style scoped>
@keyframes dots {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.75;
  }
  67% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
div > div:nth-child(1) {
  animation-name: dots;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
div > div:nth-child(2) {
  animation-name: dots;
  opacity: 0;
  animation-duration: 1s;
  animation-delay: 0.33s;
  animation-iteration-count: infinite;
}
div > div:nth-child(3) {
  animation-name: dots;
  opacity: 0;
  animation-duration: 1s;
  animation-delay: 0.66s;
  animation-iteration-count: infinite;
}
</style>
